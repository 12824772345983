import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import { useState } from 'react';

import MenuService from './services/menu-service';
import Header from './components/Header';
import Footer from './components/Footer';
import MobileMenu from './components/MobileMenu';
import NotFound from './pages/NotFound';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import Products from './pages/Products';
import Checkout from './pages/Checkout';
import Thanks from './pages/Thanks';
import Empty from './pages/Empty';

function App() {
    const [stateClass, setStateClass] = useState('');

    MenuService.onMobileMenuChange((isMobileMenuOpen) => {
        const stateClass = isMobileMenuOpen ? 'navPanel-visible' : '';
        setStateClass(stateClass);
    });

    return (
        <div className={stateClass}>
            <Router>
                <AppContent />
            </Router>
        </div>
    );
}

function AppContent() {
    const location = useLocation();
    const isEmptyPage = location.pathname === "/empty";

    return (
        <>
            <MobileMenu />
            <div id="page-wrapper">
                {!isEmptyPage && <Header />}
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/products" element={<Products />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/thanks" element={<Thanks />} />
                    <Route path="/empty" element={<Empty />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
                {!isEmptyPage && <Footer />}
            </div>
        </>
    );
}

export default App;
